<template>
  <div>
    <b-card class="mb-1">
      <b-card-header class="m-0 p-0">
        <div class="card-title">
          {{ $t('facetedSearchSettings.header') }}
        </div>
        <b-button v-b-toggle.collapse-3 variant="text-primary">
          <span class="when-open">{{ $t('facetedSearchSettings.descriptionButton.hide') }}</span>
          <span class="when-closed">{{ $t('facetedSearchSettings.descriptionButton.show') }}</span>
          {{ $t('facetedSearchSettings.descriptionButton.description') }}
          <span class="when-open"><feather-icon icon="ChevronUpIcon" /></span>
          <span class="when-closed"><feather-icon icon="ChevronDownIcon" /></span>
        </b-button>
      </b-card-header>
      <b-collapse id="collapse-3" visible>
        <div ref="container">
          <div class="mt-1 text-left w-100">
            <vs-row class="mb-1">
              {{ $t('facetedSearchSettings.description') }}
            </vs-row>
          </div>
        </div>
      </b-collapse>
    </b-card>

    <b-card :title="$t('facetedSearchSettings.listHeader')">
      <div ref="container">
        <vs-row class="mb-1">
          {{ $t('facetedSearchSettings.shortDescription') }}
        </vs-row>
        <div class="mt-2 text-left w-100">
          <vs-table class="stretchTableOne bg-gray" :data="[1]">
            <template slot="thead">
              <vs-th v-for="(name, columnIndex) in columnNamesView" :key="columnIndex">
                <span>{{ $t('facetedSearchSettings.tableHeaders.' + name) }}</span>
              </vs-th>
            </template>
            <vs-tr v-for="(item,k) in getFacetedItems" :key="k">
              <vs-td>
                {{ getFieldTranslation(item.field) }}
              </vs-td>
              <vs-td>
                {{ item.label }}
              </vs-td>
              <vs-td>
                {{ getTypeTranslation(item.type) }}
              </vs-td>
              <vs-td>
                <a class="d-b-inline" href="#" @click.prevent="move(k,k-1)">
                  <vs-tooltip :text="$t('facetedSearchSettings.upTooltip')">
                    <arrow-up-icon />
                  </vs-tooltip>
                </a>
                <a class="d-b-inline" href="#" @click.prevent="move(k,k+1)">
                  <vs-tooltip :text="$t('facetedSearchSettings.downTooltip')">
                    <arrow-down-icon />
                  </vs-tooltip>
                </a>
                <a class="d-b-inline" href="#" @click.prevent="confirmDelete(k)">
                  <vs-tooltip :text="$t('facetedSearchSettings.deleteTooltip')">
                    <trash-2-icon class="text-danger" />
                  </vs-tooltip>
                </a>
              </vs-td>
            </vs-tr>
          </vs-table>
        </div>
      </div>
    </b-card>

    <b-card :title="$t('facetedSearchSettings.headerAdd')" :hidden="!getAvailableFields.length">
      <div ref="container">
        <div class="mt-2 text-left w-100">
          <ValidationObserver ref="observer">
            <vs-table class="stretchTableOne bg-gray addTable" :data="[1]">
              <vs-tr>
                <vs-td>
                  <strong>{{ $t('facetedSearchSettings.addNewFieldLabel') }}</strong>
                </vs-td>
                <vs-td>
                  <vs-select
                    class="max-width"
                    :value="selectedField"
                    @input="updateSelectedField($event)"
                  >
                    <vs-select-item
                      v-for="(element) in getAvailableFields"
                      :key="element.value"
                      :text="element.label"
                      :value="element.value"
                    />
                  </vs-select>
                </vs-td>
              </vs-tr>
              <vs-tr>
                <vs-td>
                  <strong>{{ $t('facetedSearchSettings.addNewLabelLabel') }}</strong>
                </vs-td>
                <vs-td>
                  <validation-provider
                    v-slot="{ errors }"
                    rules="required|min:3|max:64"
                    name="facetedMenuName"
                  >
                    <vs-input
                      class="max-width"
                      type="text"
                      :value="selectedLabel"
                      @input="updateSelectedLabel($event)"
                    />
                    <span id="error">{{ errors[0] }}</span>
                  </validation-provider>
                </vs-td>
              </vs-tr>
              <vs-tr>
                <vs-td>
                  <strong>{{ $t('facetedSearchSettings.addNewTypeLabel') }}</strong>
                </vs-td>
                <vs-td>
                  <vs-select
                    class="max-width"
                    :value="selectedType"
                    @input="updateSelectedType($event)"
                  >
                    <vs-select-item
                      v-for="(element) in getAvailableTypes"
                      :key="element.value"
                      :text="element.label"
                      :value="element.value"
                    />
                  </vs-select>
                </vs-td>
              </vs-tr>
              <vs-tr v-if="isUnitType()">
                <vs-td>
                  <strong>{{ $t('facetedSearchSettings.addNewUnitLabel') }}</strong>
                </vs-td>
                <vs-td>
                  <validation-provider
                    v-slot="{ errors }"
                    :rules="{required:false,min:0,max:50}"
                    name="facetedMenuName"
                  >
                    <vs-input
                      class="max-width"
                      type="text"
                      :value="selectedUnit"
                      @input="updateSelectedUnit($event)"
                    />
                    <span id="error">{{ errors[0] }}</span>
                  </validation-provider>
                </vs-td>
              </vs-tr>
              <vs-tr>
                <vs-td>&nbsp;</vs-td>
                <vs-td>
                  <vs-button class="" style="float:right" @click="addFacetedItem()">
                    {{ $t('facetedSearchSettings.add') }}
                  </vs-button>
                </vs-td>
              </vs-tr>
            </vs-table>
          </ValidationObserver>
        </div>
      </div>
    </b-card>
    <b-card :hidden="!!getAvailableFields.length">
      <b-alert show variant="light">
        {{ $t('facetedSearchSettings.allFieldsSelected') }}
      </b-alert>
    </b-card>
  </div>
</template>

<script>
import store from '@/store'
import { ArrowDownIcon, ArrowUpIcon, Trash2Icon } from 'vue-feather-icons'

import SearchInstanceStore from '@/store/search/searchInstance'

export default {
  name: 'FacetedSearchSettings',
  components: { Trash2Icon, ArrowUpIcon, ArrowDownIcon },
  data() {
    return {
      columnNamesView: ['field', 'label', 'type', 'actionView'],
      availableType: {
        table: ['category', 'checkbox'],
        number: ['checkbox', 'range', 'rangeSlider'],
        string: ['checkbox', 'box'],
        id: ['checkbox'],
      },
      unitType: ['range', 'rangeSlider'],
      selectedField: null,
      selectedLabel: '',
      selectedUnit: null,
      selectedType: null,
    }
  },
  computed: {
    getAvailableFields() {
      let { availableFields, facetedItems } = store.state.facetedMenu
      console.log(facetedItems)
      const availableToSelectOptions = availableFields.filter(option => !facetedItems.some(selectedOption => selectedOption.field === option.path))

      availableFields = availableToSelectOptions.map(item => ({
        value: item,
        label: this.getFieldTranslation(item.path),
      }))

      return availableFields
    },
    getAvailableTypes() {
      if (this.selectedField === null) {
        return []
      }
      const availableTypeForPath = this.availableType[this.selectedField.fieldType]
      const availableTypeSelect = availableTypeForPath.map(item => ({
        value: item,
        label: this.getTypeTranslation(item),
      }))
      if (availableTypeSelect.length === 1) {
        this.selectedType = availableTypeSelect[0].value
      }
      return availableTypeSelect
    },
    getFacetedItems() {
      return store.state.facetedMenu.facetedItems
    },
    getSearchId() {
      return SearchInstanceStore.state.searchInstanceId
    },
  },
  watch: {
    getSearchId() {
      this.getData()
    },
  },
  mounted() {
    if (this.getSearchId !== '') {
      this.getData()
    }
  },
  methods: {
    isUnitType() {
      return this.unitType.indexOf(this.selectedType) >= 0
    },
    getUnit() {
      if (this.unitType.indexOf(this.selectedType) < 0) {
        return null
      }
      return this.selectedUnit
    },
    getData() {
      store.dispatch('facetedMenu/getData', this.getSearchId)
    },
    getFieldTranslation(field) {
      return this.$te(`facetedSearchSettings.fields.${field}`) ? this.$t(`facetedSearchSettings.fields.${field}`) : field
    },
    getTypeTranslation(field) {
      return this.$te(`facetedSearchSettings.types.${field}`) ? this.$t(`facetedSearchSettings.types.${field}`) : field
    },
    addFacetedItem() {
      this.$refs.observer.validate().then(result => {
        if (!result
          || (this.selectedField === null)
          || (this.selectedType === null)
          || (this.selectedLabel === null)
          || (this.selectedLabel === '')
        ) {
          return
        }
        store.dispatch('facetedMenu/add', {
          item: {
            field: this.selectedField.path,
            label: this.selectedLabel,
            type: this.selectedType,
            unit: this.getUnit(),
          },
        })
        this.selectedField = null
        this.selectedLabel = ''
        this.selectedType = null
        this.selectedUnit = null
        this.$refs.observer.reset()
      })
    },
    updateSelectedField(value) {
      this.selectedField = value
    },
    updateSelectedLabel(value) {
      this.selectedLabel = value
    },
    updateSelectedUnit(value) {
      this.selectedUnit = value
    },
    updateSelectedType(value) {
      this.selectedType = value
    },
    move(from, to) {
      store.dispatch('facetedMenu/move', {
        from,
        to,
      })
    },
    confirmDelete(key) {
      store.dispatch('facetedMenu/remove', {
        key,
      })
    },
    saveSettings() {
      store.dispatch('facetedMenu/save', this.getSearchId)
    },
  },
}
</script>

<style scoped>
.addTable * {
  background-color: #f5f5f5;
}

.addTable {
  padding: 5px;
  background-color: #f5f5f5;
  border-radius: 8px;
  margin-bottom: 20px;
}

.addTable .max-width {
  width: 100%;
}

.addTable tr td:first-of-type {
  width: 200px;
}

.d-b-inline {
  display: inline-block;
}

#error {
  color: red
}

.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}
</style>
